import * as React from "react";
import { VariantProps, cva } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";
const buttonVariants = cva("inline-flex gap-2 items-center justify-center text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background", {
  variants: {
    variant: {
      default: "rounded-full bg-accent-foreground text-primary-foreground hover:bg-primary/90",
      destructive: "rounded-full bg-destructive text-destructive-foreground hover:bg-destructive/90",
      outline: "rounded-full border border-foreground hover:bg-accent hover:text-accent-foreground",
      primary: "justify-center rounded-full bg-gradient-to-b from-[#c81720] to-[#c53038] hover:to-[#c81720] hover:from-[#c53038] border border-[#c64e54] focus:outline-none focus:ring active:text-opacity-75 text-white",
      secondary: "bg-secondary rounded-full  text-secondary-foreground hover:bg-secondary/80",
      ghost: "rounded-full hover:bg-accent hover:text-accent-foreground",
      link: "underline-offset-4 hover:underline text-primary",
      gradient: "rounded-full bg-gradient-to-b from-[#c81720] to-[#c53038] hover:to-[#c81720] hover:from-[#c53038] border border-[#c64e54] focus:outline-none focus:ring active:text-opacity-75 text-white",
      "gradient-outline": "rounded-full bg-gradient-to-b from-[#c81720] to-[#c53038] focus:outline-none focus:ring active:text-opacity-75 text-white"
    },
    size: {
      xs: "h-8 px-1 rounded-full",
      default: "h-10 py-2 px-4",
      sm: "h-9 px-3 rounded-full",
      lg: "h-11 px-8 rounded-full",
      icon: "h-10 w-10 p-0 rounded-full flex items-center justify-center shrink-0",
      xl: "h-12 px-10 rounded-full text-xl font-bold"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "default"
  }
});
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  loading?: boolean;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  className,
  variant,
  size,
  children,
  loading,
  ...props
}, ref) => {
  return variant === "gradient-outline" ? <button className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props}>
        <span className="">{children}</span>
        {loading && <Loader2 className="animate-spin" size={20} />}
      </button> : <button className={cn(buttonVariants({
    variant,
    size,
    className
  }))} ref={ref} {...props}>
        {children}
        {loading && <Loader2 className="animate-spin" size={20} />}
      </button>;
});
Button.displayName = "Button";
export { Button, buttonVariants };